import { AttachFile } from "@mui/icons-material";
import { Button } from "@mui/material";
import { ChangeEvent } from "react";
import { useApp } from "../context/AppContext";

// Define the list of accepted MIME types
const acceptedMimeTypes = [
  'text/x-c',
  'text/x-csharp',
  'text/x-c++',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/html',
  'text/x-java',
  'application/json',
  'text/markdown',
  'application/pdf',
  'text/x-php',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/x-python',
  'text/x-script.python',
  'text/x-ruby',
  'text/x-tex',
  'text/plain',
  'text/css',
  'text/javascript',
  'application/x-sh',
  'application/typescript'
];

const FileUpload = ()=>{
  const {setUploadedFile} = useApp();

  // Handle file selection
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setUploadedFile(event.target.files[0]);
    }
  };

  return (
    <>
    <input
      accept={acceptedMimeTypes.join(',')}
      id="file-upload"
      type="file"
      style={{ display: 'none' }}
      onChange={handleFileChange}
    />
    <label htmlFor="file-upload">
      <Button component="span">
        <AttachFile />
      </Button>
    </label>
    </>
  )
};

export default FileUpload;
