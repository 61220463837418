import { useState } from 'react';

const useIsHidden = () => {
  const [isHidden, setIsHidden] = useState(false);

  const toggleHidden = () => setIsHidden(!isHidden);

  return { isHidden, toggleHidden };
};

export default useIsHidden;
