import { AppBar, IconButton, LinearProgress, Toolbar, } from '@mui/material';
import { MenuOpen, PostAdd } from '@mui/icons-material';
import { useApp } from '../context/AppContext';
import AssistantsDropdown from './AssistantsDropdown';
import Alert, { AlertColor } from '@mui/material/Alert';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';


type alertState = undefined | { alert: AlertColor, message: string };
type alertShownState = { warningShown: boolean, errorShown: boolean };


const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
}));


const Header = () => {
  const { isLoading, toggleDrawer, setNewConversation, usageData } = useApp();
  const [alert, setAlert] = useState<alertState>(undefined);
  const [alertsShown, setAlertsShown] = useState<alertShownState>({ warningShown: false, errorShown: false });

  useEffect(() => {
    if (!usageData) return;

    if (usageData.total_tokens > 68000 && usageData.total_tokens < 128000 && !alertsShown.warningShown) {
      setAlert({ alert: "warning", message: "Chonk is going to start getting confused soon." });
      setAlertsShown(prevState => ({ ...prevState, warningShown: true }));
    } else if (usageData.total_tokens >= 128000 && !alertsShown.errorShown) {
      setAlert({ alert: "error", message: "Chonk is officially on the struggle bus. He will be unreliable from here." });
      setAlertsShown(prevState => ({ ...prevState, errorShown: true }));
    }
  }, [usageData, alertsShown]);

  const handleOnClose = ()=>setAlert(undefined);

  return (
    <AppBar position="sticky">
      <StyledToolBar>
        <IconButton color="inherit" onClick={toggleDrawer}>
          <MenuOpen />
        </IconButton>

        <AssistantsDropdown />

        <IconButton color="inherit" onClick={setNewConversation}>
          <PostAdd />
        </IconButton>
      </StyledToolBar>

      {isLoading ? <LinearProgress /> : null}

      {alert && (
        <Alert
          sx={{ margin: 1 }}
          severity={alert.alert}
          onClose={handleOnClose}
        >
          {alert.message}
        </Alert>
      )}
    </AppBar>
  );
};

export default Header;
