import { List, ListItem, ListItemText, ListItemButton, ListItemIcon } from '@mui/material';
import { Brightness7, Brightness4, KeyboardArrowDown } from '@mui/icons-material';
import { useMemo, memo, useCallback, SyntheticEvent } from 'react';
import { MuiColorInput } from 'mui-color-input';
import { set, throttle } from 'lodash';
import { useTheme } from '../context/CustomThemeProvider';
import { ThemeOptions, styled } from '@mui/material/styles';
import { DrawerLeftStateMenuOpen } from './DrawerLeft';


export interface SettingsListProps {
  toggleMenu: (menuName: keyof DrawerLeftStateMenuOpen) => (e: SyntheticEvent<HTMLDivElement>) => void,
  menuOpen:boolean,
}


const SettingsListKeyboardArrowDown = styled(KeyboardArrowDown, {
  shouldForwardProp: (prop) => prop !== 'menuOpen',
})<{ menuOpen: boolean }>(({ theme, menuOpen }) => ({
  ml: 5,
  transition: '0.2s',
  transform: menuOpen ? 'rotate(0)' : 'rotate(-180deg)',
}));


const SettingListItemButton = styled(ListItemButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));


const MuiColorInputStyled = styled(MuiColorInput)(({ theme }) => ({
  flex: '1 1 100%',
}));


const ThreadListItemTextField = styled(MuiColorInputStyled)(({ theme }) => ({
  flex: '1 1 100%',
  px: theme.spacing(2),
  minHeight: '40px',
  color:'inherit',
}));


const ColorPicker = (props: any) => {
  const { label, objPath, activeValue } = props;
  const { theme, updateUserTheme } = useTheme();

  const handleChange = useMemo(
    () =>
      throttle((newValue: string) => {
        const data = set({}, objPath, newValue);
        updateUserTheme(data);
      }, 500),
    [theme]
  ); // 500 ms throttle interval

  return (
    <ThreadListItemTextField
      label={label}
      format="hex"
      value={activeValue}
      onChange={handleChange}
    />
  );
};


const SettingsList = memo(({ toggleMenu, menuOpen }: SettingsListProps) => {
  const { theme, updateUserTheme } = useTheme();

  const inputList = useMemo(() => [
    { label: 'primary text color', objPath: 'palette.text.primary', activeValue: theme?.palette?.text?.primary ?? '#000000' },
    { label: 'secondary text color', objPath: 'palette.text.secondary', activeValue: theme?.palette?.text?.secondary ?? '#000000' },
    { label: 'bg color one', objPath: 'palette.background.default', activeValue: theme?.palette?.background?.default ?? '#ffffff' },
    { label: 'bg color two', objPath: 'palette.background.paper', activeValue: theme?.palette?.background?.paper ?? '#ffffff' },
  ], [theme]);

  const handleModeToggle = useCallback(() => {
    const mode = theme?.palette?.mode === 'light' ? 'dark' : 'light';
    const data: ThemeOptions = { palette: { mode } };
    updateUserTheme(data);
  }, [theme, updateUserTheme]);


  return (
    <List>
      <ListItemButton onClick={toggleMenu('settings')}>
        <ListItemText primary="Settings" secondary="Colors and the like" />
        <SettingsListKeyboardArrowDown menuOpen={menuOpen} />
      </ListItemButton>

      {
        menuOpen &&
        <ListItem>
          <SettingListItemButton
            onClick={handleModeToggle}
            color="inherit"
          >
            <ListItemIcon>
              {theme?.palette?.mode === 'dark' ? <Brightness4 /> : <Brightness7 />}
            </ListItemIcon>
            <ListItemText primary={`${theme?.palette?.mode} mode`} />
          </SettingListItemButton>
        </ListItem>
      }

      {
        menuOpen &&
        inputList.map((item, index) => (
          <ListItem key={index}>
            <ColorPicker {...item} />
          </ListItem>
        ))
      }
    </List>
  );
});


export default SettingsList;
