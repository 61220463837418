import { useState } from 'react';
import { iUseThreads } from '../types/hooks';
import { tThreadData } from '../types/openai';

const useThreads = (): iUseThreads => {
  const [threadId, setThreadId] = useState<undefined | string>(undefined);
  const [threads, setThreads] = useState<undefined | iUseThreads['threads']>(
    undefined
  );
  const [error, setError] = useState<undefined | string>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const appendThread = (data: tThreadData) => {
    if (!threads || !data) return;

    const newThreads = [...threads, data];

    setThreads(newThreads);
  };

  const deleteThread = async (threadId: string) => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await fetch(`/api/threads/${threadId}/delete`, {
        method: 'DELETE',
      });

      const json = await response.json();
      const deletedThreadData = json.payload[0];
      const filtered = threads?.filter(
        (obj) => obj.id !== deletedThreadData.id
      );

      if (!filtered) {
        throw new Error(
          'Something went wrong in the filtering of the new thread data when deleting the thread'
        );
      }

      const newThreads = [...filtered];
      setThreads(newThreads);
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error:', error.message);
        setError(error.message);
      } else {
        console.error('Unexpected error:', error);
        setError('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateThread = async (threadId: string, newValue: string) => {
    setIsLoading(true);
    setError(undefined);

    try {
      const filtered = threads?.filter((obj) => obj.thread_id === threadId);

      if (!filtered) return;

      const payload = Object.assign({}, filtered[0], { name: newValue });

      const response = await fetch(`/api/threads/${threadId}/update`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const json = await response.json();
      const updatedThread = json.payload[0];
      const updatedThreads = threads?.map((threadData) => {
        if (threadData.thread_id !== updatedThread.thread_id) {
          return threadData;
        } else {
          return updatedThread;
        }
      });

      setThreads(updatedThreads);
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error:', error.message);
        setError(error.message);
      } else {
        console.error('Unexpected error:', error);
        setError('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    threadId,
    setThreadId,
    threads,
    setThreads,
    appendThread,
    deleteThread,
    updateThread,
    isLoading,
  };
};

export default useThreads;
