import {Divider,Drawer,List,ListItem,ListItemText,Theme,Button,ListItemButton,} from '@mui/material';
import { Close, } from '@mui/icons-material';
import { SyntheticEvent, memo, useState } from 'react';
import { useTheme } from '../context/CustomThemeProvider';
import { useApp } from '../context/AppContext';
import ThreadsList from './ThreadsList';
import SettingsList from './SettingsList';


export interface DrawerLeftStateMenuOpen {
  threadsList:boolean,
  settings:boolean,
}


const DrawerPaperStyles = (theme: Theme) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: '50%',
  },
});


const CloseButtonStyles = (theme: Theme) => ({
  marginLeft: 'auto',
});


const DrawerLeft = () => {
  const { drawerOpen, toggleDrawer } = useApp();
  const { setDefaults } = useTheme();
  const [menuOpen, setMenuOpen] = useState<DrawerLeftStateMenuOpen>({
    threadsList: true,
    settings: false,
  });

  const toggleMenu =
    (menuName: keyof typeof menuOpen) =>
    (event: SyntheticEvent<HTMLDivElement>) => {
      setMenuOpen({ ...menuOpen, [menuName]: !menuOpen[menuName] });
    };

  return (
    <Drawer
      open={drawerOpen}
      onClose={toggleDrawer}
      PaperProps={{ sx: DrawerPaperStyles }}
    >
      <List disablePadding>
        <ListItem disableGutters disablePadding>
          <Button
            sx={CloseButtonStyles}
            onClick={toggleDrawer}
            endIcon={<Close />}
          >
            close
          </Button>
        </ListItem>
      </List>

      <Divider />
        <ThreadsList toggleMenu={toggleMenu} menuOpen={menuOpen.threadsList} />
      <Divider />
        <SettingsList toggleMenu={toggleMenu} menuOpen={menuOpen.settings} />
      <Divider />
      <List disablePadding>
        <ListItemButton onClick={setDefaults}>
          <ListItemText
            primary="restore defaults"
            primaryTypographyProps={{ color: 'secondary', textAlign: 'center' }}
          />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default memo(DrawerLeft);
