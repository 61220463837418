import { useState } from 'react';
import { iUseAssistants } from '../types/hooks';

const useAssistants = (): iUseAssistants => {
  const [assistant, setAssistant] =
    useState<iUseAssistants['assistant']>('chonk');
  const [assistants, setAssistants] = useState<
    iUseAssistants['assistants'] | undefined
  >(undefined);

  const setDefault = () => {
    setAssistant('chonk');
  };

  return { assistant, assistants, setAssistant, setAssistants, setDefault };
};

export default useAssistants;
