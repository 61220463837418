import { ArrowUpward } from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { useApp } from "../context/AppContext";
import FileUpload from "./FileUpload";


const UserInputContainer = styled(Box)(({ theme }) => ({
  boxShadow:theme.shadows[4],
  paddingBlock:theme.spacing(1.5),
}));


const UserInputActions = styled(Box)(({ theme }) => ({
  display:"flex",
  justifyContent:"space-between",
  alignItems:"flex-end",
  [theme.breakpoints.up('md')]: {
    maxWidth:"80vmin",
    margin:"auto",
  },
}));


const UserInput = ()=>{
  const {submitUserInput} = useApp();
  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (event:ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeydown = (event:KeyboardEvent)=>{
    if(event.key === "Enter" && !event.shiftKey){
      event.preventDefault();
      sendMessage();
    };
  };

  const sendMessage = ()=>{
    if (!inputValue) return;
    submitUserInput(inputValue);
    setInputValue('');
  };

  return (
    <UserInputContainer>
      <UserInputActions>

        <FileUpload />

        <TextField
          placeholder="Message Chonk..."
          fullWidth
          multiline
          maxRows={7}
          onKeyDown={handleKeydown}
          onChange={handleInputChange}
          value={inputValue}
          variant="standard"
        />

        <Button
          aria-label="submit"
          onClick={sendMessage}>
          <ArrowUpward />
        </Button>
      </UserInputActions>
    </UserInputContainer>
  )
}

export default UserInput;
