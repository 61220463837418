import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from 'react';
import { createTheme, ThemeProvider, ThemeOptions } from '@mui/material/styles';
import { iThemeContext } from '../types/context';
import { CssBaseline } from '@mui/material';
import _ from 'lodash';

const ThemeContext = createContext<iThemeContext | undefined>(undefined);

const CustomThemeProvider = ({ children }: { children: ReactNode }) => {
  const [themeOptions, setThemeOptions] = useState<ThemeOptions>(() => {
    // Initially create the theme from stored user preferences or default to an empty object
    const storedTheme = localStorage.getItem('userCustomTheme');
    return storedTheme ? JSON.parse(storedTheme) : {};
  });

  useEffect(() => {
    // Serialize and store the current theme options to localStorage whenever they change
    localStorage.setItem('userCustomTheme', JSON.stringify(themeOptions));
  }, [themeOptions]);

  const updateUserTheme = (newThemeOptions: ThemeOptions | undefined) => {
    if (!newThemeOptions) return setThemeOptions({});

    // Merge existing theme with current theme
    setThemeOptions((currentThemeOptions) =>
      _.merge({}, currentThemeOptions, newThemeOptions)
    );
  };

  const setDefaults = () => updateUserTheme(undefined);

  const theme = useMemo(() => createTheme(themeOptions), [themeOptions]);

  const value = { theme, updateUserTheme, setDefaults };

  return (
    <ThemeContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export { CustomThemeProvider, useTheme };
