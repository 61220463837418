import { createContext, useContext, useState, useMemo, ReactNode, useEffect, useCallback } from 'react';
import { iAppContext } from '../types/context';
import useAppData from '../hooks/useAppData';
import useMessages from '../hooks/useMessages';
import useAssistants from '../hooks/useAssistants';
import useThreads from '../hooks/useThreads';

const AppContext = createContext<iAppContext|undefined>(undefined);

const AppProvider = ({ children }:{ children:ReactNode }) => {
    const {appData, error} = useAppData();
    const [isLoading, setIsLoading] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const {assistant, assistants, setAssistant, setAssistants, setDefault:setDefaultAssistant} = useAssistants();
    const {threadId, setThreadId, threads, setThreads, appendThread, deleteThread, updateThread, isLoading:threadLoading} = useThreads();
    const {fetchMessages, messages, setMessages, submitMessage, usageData, isLoading:messageLoading} = useMessages();
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);

    const toggleDrawer = ()=>{
        setDrawerOpen(!drawerOpen);
    };

    const deleteConversation = async (threadId:string)=>{
        if (!threadId)return;
        const deleted = await deleteThread(threadId);
    };

    const getPreviousConversation = async (id:string)=>{
        setThreadId(id);
        const priorAssistant = await fetchMessages(id);
        if (!priorAssistant)return;
        setAssistant(priorAssistant);
    };

    const setNewConversation = ()=>{
        setThreadId(undefined);
        setMessages(undefined);
        setDefaultAssistant();
    };

    const updateConversation = async (threadId:string,newValue:string)=>{
        const thread = await updateThread(threadId,newValue);
    };

    const submitUserInput = async (userInput:string)=>{
        const threadData = await submitMessage(threadId, assistant, userInput, uploadedFile);

        if (!threadData)return;
        appendThread(threadData);
        setThreadId(threadData.thread_id);
    };

    useEffect(()=>{
        if (!appData)return;
        setThreads([...appData.threadData]);

        if (!appData.assistantsList)return;
        setAssistants([...appData.assistantsList]);
    },[appData]);
    
    useEffect(() => {
        const loadingStates = [threadLoading,messageLoading];
        setIsLoading(loadingStates.some(loading => loading));
    }, [threadLoading,messageLoading]);

    const contextValue = useMemo(() => ({
        appData,
        isLoading,
        assistant,
        assistants,
        setAssistant,
        drawerOpen,
        toggleDrawer,
        threads,
        fetchMessages,
        setThreadId,
        getPreviousConversation,
        messages,
        setNewConversation,
        submitUserInput,
        deleteConversation,
        updateConversation,
        setUploadedFile,
        uploadedFile,
        usageData,
    }), [appData,isLoading,assistants,drawerOpen,messages,threads,assistant,uploadedFile, usageData]);

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};

const useApp = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useApp must be used within an AppProvider');
    }
    return context;
};

export { AppProvider, useApp };
