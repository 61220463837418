import { useEffect, useState } from 'react';
import { iAppData, iUseAppData } from '../types/hooks';

const URL = '/api';

const useAppData = (): iUseAppData => {
  const [appData, setAppData] = useState<undefined | iAppData>(undefined);
  const [error, setError] = useState<undefined | string>(undefined);

  useEffect(() => {
    const send = async () => {
      try {
        const response = await fetch(URL);

        if (!response.ok) {
          throw new Error(
            `HTTP status ${response.status}: ${response.statusText}`
          );
        }

        const result = await response.json();

        if (!result.payload) {
          throw new Error('No payload found in useAppData request');
        }

        setAppData(result.payload);
      } catch (error) {
        if (error instanceof Error) {
          console.error('Error:', error.message);
          setError(error.message);
        } else {
          console.error('Unexpected error:', error);
          setError('An unexpected error occurred');
        }
      }
    };

    send();
  }, []);

  return { appData, setAppData, error };
};

export default useAppData;
