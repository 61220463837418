import { List, ListItem, ListItemText, ListItemButton, TextField, IconButton, styled } from '@mui/material';
import { Edit, DeleteForever, Check, Cancel, KeyboardArrowDown } from '@mui/icons-material';
import { SyntheticEvent, useEffect, useRef, useState, KeyboardEvent, memo } from 'react';
import { useApp } from '../context/AppContext';
import useIsHidden from '../hooks/useIsHidden';
import { iAppContext } from '../types/context';
import { DrawerLeftStateMenuOpen } from './DrawerLeft';


export interface ThreadsListProps {
  toggleMenu: (menuName: keyof DrawerLeftStateMenuOpen) => (e: SyntheticEvent<HTMLDivElement>) => void,
  menuOpen:boolean,
}


export interface ActionButtonsProps {
  isHidden?: boolean;
  handleSuccessClick?: () => void;
  toggleHidden?: () => void;
  handleClickDelete?: () => void;
}


const ThreadsListKeyboardDownArrow = styled(KeyboardArrowDown, {
  shouldForwardProp: (prop) => prop !== 'menuOpen',
})<{ menuOpen: boolean }>(({ theme, menuOpen }) => ({
  ml: 5,
  transition: '0.2s',
  transform: menuOpen ? 'rotate(0)' : 'rotate(-180deg)',
}));


// Separate component for Edit/Delete buttons
const EditDeleteButtons = ({ toggleHidden, handleClickDelete }: ActionButtonsProps) => (
  <>
    <IconButton onClick={toggleHidden} aria-label="edit" color="secondary">
      <Edit />
    </IconButton>
    <IconButton color="error" aria-label="delete" onClick={handleClickDelete}>
      <DeleteForever />
    </IconButton>
  </>
);

// Separate component for Success/Cancel buttons
const SuccessCancelButtons = ({ handleSuccessClick, toggleHidden }: ActionButtonsProps) => (
  <>
    <IconButton color="success" onClick={handleSuccessClick}>
      <Check />
    </IconButton>
    <IconButton color="error" onClick={toggleHidden}>
      <Cancel />
    </IconButton>
  </>
);

// Put all the buttons into one
const ActionButtons = ({ isHidden, handleSuccessClick, toggleHidden, handleClickDelete }: ActionButtonsProps) => (
  <>
    {isHidden ? (
      <SuccessCancelButtons handleSuccessClick={handleSuccessClick} toggleHidden={toggleHidden} />
    ) : (
      <EditDeleteButtons toggleHidden={toggleHidden} handleClickDelete={handleClickDelete} />
    )}
  </>
)


const ThreadListItemTextField = styled(TextField)(({ theme }) => ({
  flex: '1 1 100%',
  px: `${theme.spacing(2)}`,
  minHeight: '40px',
}));


const ThreadListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginX: `${theme.spacing(1)}`,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textAlign: 'left',
  flex: '1 1 100%',
  textOverflow: 'ellipsis',
}));


const ThreadsListItem = (data: Exclude<iAppContext['threads'], undefined>[number]) => {
  const { getPreviousConversation, toggleDrawer, deleteConversation, updateConversation, } = useApp();
  const { isHidden, toggleHidden } = useIsHidden();
  const [ inputValue, setInputValue ] = useState('');
  const previousInputValue = useRef(inputValue);

  useEffect(() => {
    previousInputValue.current = inputValue;
  }, [inputValue]);

  const handleKeydown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      updateConversation(data.thread_id, inputValue);
      toggleHidden();
    }
  };

  const handleButtonClick = () => {
    getPreviousConversation(data.thread_id);
    toggleDrawer();
  };

  const handleSuccessClick = () => {
    updateConversation(data.thread_id, inputValue);
    toggleHidden();
  };

  const handleClickDelete = () => {
    deleteConversation(data.thread_id);
  };

  return (
    <ListItem
      secondaryAction={
        <ActionButtons
          isHidden={isHidden}
          handleSuccessClick={handleSuccessClick}
          toggleHidden={toggleHidden}
          handleClickDelete={handleClickDelete}
        />
      }
    >

      {
        !isHidden &&
        <ThreadListItemButton
          onClick={handleButtonClick}
        >
          {data.name}
        </ThreadListItemButton>
      }

      {
        isHidden &&
        <ThreadListItemTextField 
          variant='standard'
          placeholder={data.name}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeydown}
        />
      }
    </ListItem>
  );
};


const ThreadsList = memo((props:ThreadsListProps)=>{
  const { toggleMenu, menuOpen } = props;
  const { threads } = useApp();

  //threads helper
  const renderThreads = () => {
    if (!threads || threads.length === 0) {
      return (
        <ListItem>
          <ListItemText
            primaryTypographyProps={{ textAlign: 'center' }}
            primary="You have no conversations yet. It's ok, don't be shy, chonk is friendly"
          />
        </ListItem>
      );
    }

    return threads.map((data, index) => (
      <ThreadsListItem key={index} {...data} />
    ));
  };

  return (
    <List>
      <ListItemButton onClick={toggleMenu('threadsList')}>
        <ListItemText
          primary="conversations"
          secondary="All previous conversations"
        />

        <ThreadsListKeyboardDownArrow menuOpen={menuOpen} />
      </ListItemButton>

      {menuOpen && renderThreads()}
    </List>
  );
});

export default ThreadsList