import { Card, CardContent, Typography, Box, Stack, Button } from "@mui/material";
import { useApp } from "../context/AppContext";
import { styled } from '@mui/material/styles';
import { useEffect, useRef } from "react";
import { darken } from "@mui/material";
import { iContent } from "../types/openai";
import Markdown from "react-markdown";
import hljs from "highlight.js";
import chonk_image from "../../src/assets/images/chonk_image.png";


const GridContainer = styled(Box)(({ theme }) => ({
  backgroundColor:`${theme.palette.mode === 'light' ? darken(theme.palette.background.default, 0.1) : theme.palette.background.default}`,
  display:"grid",
  gridTemplateColumns:"minmax(300px,100%)",
  rowGap:theme.spacing(1),
  overflowY:"auto",
  maxHeight:"100%",
}));

const GridItem = styled(Box)<{isuser:string}>(({ theme, isuser }) => ({
  padding: theme.spacing(2),
  scrollbarWidth: "thin",
  scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.default}`,
  backgroundColor: isuser === "user" ?
  "unset" :
  ( theme.palette.mode === 'light' ? theme.palette.background.default : darken(theme.palette.background.default, 0.5)),
}));

const GridItemContent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth:"80vmin",
    margin:"auto",
  },
}));

const DynamicContent = (content: iContent) => {
  const text = content.text?.value;

  if (!text) {
    return <Box>Text Content Not Found</Box>
  }

  // Regular expression to find code fences
  const regex = /```([a-zA-Z]*)\s*([\s\S]*?)```/g;

  // Array to hold the results
  let results = [];
  let lastIndex = 0;
  let match;

  // Find matches and separate code from text using the .exec method
  while ((match = regex.exec(text)) !== null) {
    const index = match.index;
    const language = match[1];
    const codeContent = match[2];

    // Add the text before the code fence
    if (index > lastIndex) {
      results.push({ type: 'text', content: text.slice(lastIndex, index), clipboard: text.slice(lastIndex, index), });
    }

    // Determine the language type or default to 'code' if not specified
    const codeType = language || 'code';

    // Add the code fence
    results.push(
      {
        type: codeType,
        content: `\`\`\`${codeType}\r\n${codeContent.trim()}\r\n\`\`\``,
        clipboard: codeContent.trim(),
      });

    // Update lastIndex to the end of the current match
    lastIndex = regex.lastIndex;
  }

  // Add any remaining text after the last code fence
  if (lastIndex < text.length) {
    results.push({ type: 'text', content: text.slice(lastIndex), clipboard: text.slice(lastIndex), });
  }

  const copyToClipboard = (text: string) => (event: React.SyntheticEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        // Success action, e.g., show a success message
        console.log("Text copied to clipboard successfully!");
      })
      .catch((error) => {
        // Error handling
        console.error("Failed to copy text: ", error);
      });
  };

  return (
    <>
      {results.map((obj,index) => {
        return obj.type === "text" ?
          <Markdown key={index}>{obj.content}</Markdown> :
          <Card key={index}>
            <CardContent sx={{ padding: 0 }}>
              <Stack flexDirection="row" justifyContent="space-between" paddingX={1}>
                <Typography variant="overline" padding={1}>{obj.type}</Typography>
                <Button onClick={copyToClipboard(obj.clipboard)} variant="text" size="small">copy code</Button>
              </Stack>
              <Markdown>{obj.content}</Markdown>
            </CardContent>
          </Card>
      })}
    </>
  )
};

const Main = ()=>{
  const {messages} = useApp();
  const gridContainer = useRef<HTMLDivElement>(null);
  const alignContent = messages && messages.length > 0 ? "flex-start" : "unset";

  useEffect(() => {
    //Highlight programming languages
    hljs.highlightAll();

    //Smooth scroll to bottom of conversation
    if (gridContainer.current) {
      // Set a timeout to allow the initial render to complete
      const timer = setTimeout(() => {
        // Smooth scroll to the bottom
        gridContainer.current?.scrollTo({
          top: gridContainer.current.scrollHeight,
          behavior: 'smooth'
        });
      }, 100); // Adjust the timeout duration as needed
  
      // Clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [messages]);

  return (
    <GridContainer component="main" ref={gridContainer} alignContent={alignContent}>
      { messages && messages.length > 0 ? (
        messages?.map((data,index)=>{
          const row = index % 2 === 0 ? 0 : 1;
          const content = data.content[0];
          const isuser = data.role === "user" ? "user" : "assistant";

          return (
            <GridItem key={index} isuser={isuser}>
              <GridItemContent>
                {data.role === "user" ? <Typography sx={{whiteSpaceCollapse:"break-spaces"}}>{content.text?.value}</Typography> : <DynamicContent {...content} /> }
              </GridItemContent>
            </GridItem>
          )
        }))
        :
        <GridItem isuser="assistant">
          <GridItemContent display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
            <img src={chonk_image} width="150" style={{filter:"invert(0.15)",marginBottom:"1em"}}/>
            <Typography color="GrayText">Chonkyboi Ready</Typography>
          </GridItemContent>
        </GridItem>
      }
    </GridContainer>
  )
};

export default Main;
