import { AppProvider } from './context/AppContext';
import { CustomThemeProvider } from './context/CustomThemeProvider';
import Header from './components/Header';
import DrawerLeft from './components/DrawerLeft';
import { Footer } from './components/Footer';
import UserInput from './components/UserInput';
import Main from './components/Main';

import './App.css';
import 'highlight.js/styles/atom-one-dark.css'; //Snippet theme. @https://highlightjs.org/demo

export default function App() {
  return (
    <CustomThemeProvider>
      <AppProvider>
        <Header />
        <DrawerLeft />
        <Main />
        <UserInput />
        <Footer />
      </AppProvider>
    </CustomThemeProvider>
  );
}
