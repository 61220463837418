import { useState } from 'react';
import { iUseMessages } from '../types/hooks';
import { iMessage, tThreadData, tAssistantsList } from '../types/openai';


const useMessages = ():iUseMessages => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<undefined|string>(undefined);
    const [messages, setMessages] = useState<undefined|iMessage[]>(undefined);
    const [usageData, setUsageData] = useState<undefined|iUseMessages['usageData']>(undefined);

    const submitMessage = async (threadId:string|undefined, assistant:string, userInput:string, uploadedFile:File|null):Promise<tThreadData|undefined> => {
      setIsLoading(true);
      setError(undefined);

      try {
          const formData = new FormData();

          formData.append('text',assistant);
          formData.append('text',userInput);

          if (!threadId){
            threadId = "";
          }

          formData.append('text',threadId);

          if (uploadedFile){
            formData.append('file', uploadedFile);
          }

          const response = await fetch(`/api/threads/runs`, {
              method: 'POST',
              body: formData,
          });
          
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const result = await response.json();
          
          setMessages(result.payload.messages);

          if (result.payload.usageData){
            setUsageData(result.payload.usageData)
          }

          return result.payload.thread;
      } catch (error) {
        if (error instanceof Error) {
          console.error('Error:', error.message);
          setError(error.message);
        } else {
          console.error('Unexpected error:', error);
          setError('An unexpected error occurred');
        }
      } finally {
          setIsLoading(false);
      }
    };

    const fetchMessages = async (threadId:string):Promise<void|tAssistantsList> => {
      setIsLoading(true);
      setError(undefined);

      try {
          const response = await fetch(`/api/threads/${threadId}/messages`);
          
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const result = await response.json();

          setMessages(result.payload);

          const assistant = result.payload[1].assistant_id;
          return assistant;
      } catch (error) {
        if (error instanceof Error) {
          console.error('Error:', error.message);
          setError(error.message);
        } else {
          console.error('Unexpected error:', error);
          setError('An unexpected error occurred');
        }
      } finally {
          setIsLoading(false);
      }
    };

    return {isLoading, error, submitMessage, fetchMessages, messages, setMessages, usageData};
}

export default useMessages;
