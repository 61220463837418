import { useEffect, useState, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { useApp } from '../context/AppContext';
import { tAssistantsList } from '../types/openai';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const StyledSelect = styled(Select)(({ theme }) => ({
  color: 'inherit',
  '&:before': { display: 'none' }, // Remove underline
  '&:after': { display: 'none' }, // Remove underline
  '& .MuiSelect-icon': { color: 'inherit', }, // Arrow Icon Color
}));

const AssistantsDropdown = () => {
  const { assistants, setAssistant, assistant } = useApp();
  const [value, setValue] = useState(assistant);

  useEffect(() => {
    setValue(assistant);
  }, [assistant]);

  const handleChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    const selectedAssistant = event.target.value as tAssistantsList;
    setValue(selectedAssistant);
    setAssistant(selectedAssistant);
  };

  return (
    <StyledSelect
      value={value}
      variant="standard"
      onChange={handleChange}
    >
      {assistants &&
        assistants.map((value: string, index: number) => {
          return (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          );
        })}
    </StyledSelect>
  );
};

export default AssistantsDropdown;
